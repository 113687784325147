import React from "react";

const LinkedIn = ({ url, children, ...props }) => {
  if (!url) return null;

  const urlParams = new URLSearchParams({
    url: new URL(url).toString(),
  });

  return (
    <a
      href={"https://www.linkedin.com/sharing/share-offsite/?" + urlParams}
      target="_blank"
      rel="noreferrer"
      {...props}
    >
      {children}
    </a>
  );
};

export default LinkedIn;
