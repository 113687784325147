import React, { useState } from "react";
import FullScreenButton from "../FullScreenButton";
import Slider from "../Slider";
import Lightbox from "../Lightbox";

import "./Gallery.scss";

const Gallery = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const handleClickImage = (e) => {
    e && e.preventDefault();
    setIsOpen(true);
  };

  return (
    <div className="gallery">
      <Slider images={images} setCurrentIndex={setCurrentIndex} />
      <FullScreenButton className="gallery__full-screen-button" onClick={(e) => handleClickImage(e, currentIndex)} />
      {isOpen && (
        <Lightbox
          images={images}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          onClose={() => setIsOpen(false)}
        />
      )}
    </div>
  );
};

export default Gallery;
