import React from "react";
import clsx from "clsx";
import FullScreenIcon from "/src/images/svgs/full-screen.svg";

import "./FullScreenButton.scss";

const FullScreenButton = ({ className, ...props }) => {
  return (
    <button className={clsx("full-screen u-shadow u-hover-grow", className)} {...props}>
      <FullScreenIcon className="full-screen__icon" />
    </button>
  );
};

export default FullScreenButton;
