import React from "react";
import { useTranslation } from "@w3f/gatsby-theme-w3f/src/utils/i18n";

const Email = ({ subject, url, excerpt, children, ...props }) => {
  if (!subject) return null;
  const { t } = useTranslation();

  const emailSubject = `subject=${encodeURIComponent(subject)}`;

  const body = `${t("Checkout this article")}: ${url}\n\n${t("Excerpt")}: ${excerpt}`;
  const emailBody = `body=${encodeURIComponent(body)}`;

  return (
    <a href={`mailto:?${emailSubject}&${emailBody}`} target="_blank" rel="noreferrer" {...props}>
      {children}
    </a>
  );
};

export default Email;
