import React from "react";
import ReactImageLightbox from "react-image-lightbox";

import "./Lightbox.scss";

const Lightbox = ({ images, currentIndex, setCurrentIndex, onClose }) => {
  return (
    <ReactImageLightbox
      mainSrc={images[Number(currentIndex)].image.publicURL}
      nextSrc={images[(currentIndex + 1) % images.length].image.publicURL}
      prevSrc={images[(currentIndex + images.length - 1) % images.length].image.publicURL}
      onCloseRequest={onClose}
      onMovePrevRequest={() => setCurrentIndex((currentIndex + images.length - 1) % images.length)}
      onMoveNextRequest={() => setCurrentIndex((currentIndex + 1) % images.length)}
    />
  );
};

export default Lightbox;
