import React from "react";

const Facebook = ({ url, title, children, ...props }) => {
  if (!url) return null;

  const urlParams = new URLSearchParams({
    u: new URL(url).toString(),
    t: title,
  });

  return (
    <a href={"https://www.facebook.com/sharer/sharer.php?" + urlParams} target="_blank" rel="noreferrer" {...props}>
      {children}
    </a>
  );
};

export default Facebook;
