import React from "react";
import { graphql } from "gatsby";
import Layout from "@w3f/gatsby-theme-w3f/src/components/site/Layout";
import SEO from "@w3f/gatsby-theme-w3f/src/components/site/SEO";
import SocialShare from "../../components/SocialShare";
import Gallery from "../../components/Gallery";
import FeaturedImage from "../../components/FeaturedImage";
import Container from "layouts/Container";
import ArrowLink from "elements/ArrowLink";

import "./PressArticle.scss";

export default function PressArticlePage({ data: { article, footerImage }, location }) {
  const { title, created_at, excerpt, featuredImage, gallery, seo } = article.frontmatter;

  const useGallery = (gallery && gallery.length) > 0;

  return (
    <Layout footerImage={footerImage}>
      {/* Adds default coming from the article, but can be overwritten by Seo fields */}
      <SEO
        title={(seo && seo.title) || title}
        description={(seo && seo.description) || excerpt}
        image={(seo && seo.image) || featuredImage}
      />
      <Container>
        <div className="press-article">
          <ArrowLink className="press-article__back-link" to="/press/" reversed>
            Back
          </ArrowLink>
          <time className="press-article__date">{created_at}</time>
          <h1 className="press-article__title t-title-1">{title}</h1>
          <hr className="press-article__divider" />
          {useGallery ? <Gallery images={gallery} /> : <FeaturedImage image={featuredImage} />}
          <div className="press-article__body t-text t-sm" dangerouslySetInnerHTML={{ __html: article.html }} />
          <SocialShare
            className="press-article__share"
            title={title}
            url={location.href}
            excerpt={excerpt || article.excerpt}
          />
        </div>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String!, $language: String!) {
    ...Locales
    article: markdownRemark(
      fields: { slug: { eq: $slug }, langKey: { eq: $language } }
      fileAbsolutePath: { regex: "//press-articles//" }
    ) {
      html
      excerpt
      frontmatter {
        title
        slug
        created_at(formatString: "dddd, LL")
        excerpt
        gallery {
          description
          caption
          image {
            publicURL
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
        seo {
          ...Seo
        }
        featuredImage: featured_image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
    latestPosts: allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___created_at }
      filter: {
        fields: { langKey: { eq: $language } }
        fileAbsolutePath: { regex: "//press-articles//" }
        frontmatter: { draft: { ne: true } }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            created_at(formatString: "dddd, LL")
          }
          fields {
            slug
          }
        }
      }
    }
    relatedPosts: allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___created_at }
      filter: {
        fields: { langKey: { eq: $language } }
        fileAbsolutePath: { regex: "//press-articles//" }
        frontmatter: { draft: { ne: true } }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            created_at(formatString: "dddd, LL")
          }
          fields {
            slug
          }
        }
      }
    }
    footerImage: file(name: { eq: "projects-large" }) {
      ...BgImage
    }
  }
`;
