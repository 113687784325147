import React from "react";
import clsx from "clsx";
import Twitter from "./Twitter";
import Facebook from "./Facebook";
import LinkedIn from "./LinkedIn";
import Email from "./Email";
import TwitterShareIcon from "/src/images/svgs/share-twitter.svg";
import FacebookShareIcon from "/src/images/svgs/share-facebook.svg";
import LinkedInShareIcon from "/src/images/svgs/share-linkedin.svg";
import EmailShareIcon from "/src/images/svgs/share-email.svg";

import "./SocialShare.scss";

const SocialShare = ({ className, url, excerpt, title }) => {
  return (
    <div className={clsx("social-share", className)}>
      <h3 className="t-title-3">Share</h3>
      <div className="social-share__items">
        <Twitter className="social-share__item u-hover-grow" url={url} text={excerpt}>
          <TwitterShareIcon />
        </Twitter>
        <Facebook className="social-share__item u-hover-grow" url={url} title={title}>
          <FacebookShareIcon />
        </Facebook>
        <LinkedIn className="social-share__item u-hover-grow" url={url}>
          <LinkedInShareIcon />
        </LinkedIn>
        <Email className="social-share__item u-hover-grow" subject={title} url={url} excerpt={excerpt}>
          <EmailShareIcon />
        </Email>
      </div>
    </div>
  );
};

export default SocialShare;
