import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import SlickSlider from "react-slick";

import "./Slider.scss";

const Slider = ({ images, setCurrentIndex }) => {
  const settings = {
    customPaging: (i) => {
      return (
        <a className="gallery__dot">
          <GatsbyImage className="gallery__thumbnail" image={getImage(images[Number(i)].image)} alt="" />
        </a>
      );
    },
    arrows: false,
    dots: true,
    dotsClass: "gallery__dots",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setCurrentIndex(next),
  };

  return (
    <SlickSlider {...settings}>
      {images.map((item, index) => {
        const { image } = item;
        return <GatsbyImage key={index} image={getImage(image)} />;
      })}
    </SlickSlider>
  );
};

export default Slider;
