import React from "react";

const Twitter = ({ text, url, via = "Web3foundation", children, ...props }) => {
  if (!url) return null;

  const urlParams = new URLSearchParams({
    url: new URL(url).toString(),
    text,
    via,
  });

  return (
    <a href={"https://twitter.com/intent/tweet?" + urlParams} {...props} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};

export default Twitter;
